<template>
  <v-app class="app-start">
    <menu-view-vue/>
  </v-app>
</template>

<script>

import MenuViewVue from './views/MenuView.vue';

export default {
  name: 'App',
  components: {
    MenuViewVue
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>

</style>
